import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
deckDeckGoHighlightElement();

export default function Articles({ data, location }) {
  const article = data.mdx;
  const pageTitle = article.frontmatter.title;
  return (
    <Layout pageTitle={pageTitle}>
      <Seo
        pageTitle={pageTitle}
        description={article.frontmatter.description || article.excerpt}
        pathname={location.pathname}
        image={article.frontmatter.coverImage.publicURL}
      />
      <Article pageTitle={pageTitle}>
        <MDXRenderer>{article.body}</MDXRenderer>
      </Article>
    </Layout>
  );
}

export const query = graphql`
  query ArticleBySlug($slug: String) {
    mdx(slug: { eq: $slug }, fields: { source: { eq: "articles" } }) {
      id
      slug
      body
      frontmatter {
        date
        title
        description
        coverImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          publicURL
        }
      }
    }
  }
`;
